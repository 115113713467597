<template>
  <v-container>
    <v-toolbar flat>
      <v-toolbar-title color="primary">
        <h4 color="primary">
          {{ $t("message.title-edit-locker") }}
        </h4>
      </v-toolbar-title>
    </v-toolbar>

    <div>
      <v-row cols="12" align="center">
        <v-col cols="12" md="4" xs="1" class="col-padding-label">
          <span class="label-title">{{ $t("message.label-id") }}</span>
        </v-col>
        <v-col md="6" class="col-padding-value">
          <v-text-field
            value="T7123-XXX-XXX"
            class="field-input"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row cols="12" align="center">
        <v-col cols="12" md="4" xs="1" class="col-padding-label">
          <span class="label-title">{{ $t("message.label-name") }}</span>
        </v-col>
        <v-col md="6" class="col-padding-value">
          <v-text-field outlined class="field-input"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="2"
          xs="3"
          class="col-padding-label nested-label-col-1"
        >
          <span class="label label-title">{{ $t("message.label-location") }}</span>
        </v-col>

        <v-col cols="4" md="2" class="col-padding-label sublist-mobile-col">
          <v-row class="mb-10">
            <span class="label label-title sublist-mobile-title"
              >{{ $t("message.label-postal") }}</span
            >
          </v-row>
          <v-row class="col-padding-label">
            <span class="label label-title sublist-mobile-title">{{ $t("message.label-address") }}</span>
          </v-row>
        </v-col>

        <v-col cols="4" md="6" class="sublist-mobile-col">
          <v-text-field outlined class="field-input"></v-text-field>
          <v-textarea
            name="input-7-4"
            outlined
            class=" field-input field-input-address text-area-class"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row align="center" justify="start">
        <v-col cols="12" md="4" sm="1" xs="4" class="col-padding-label">
          <span class="label-title">{{ $t("message.label-capacity") }}</span>
        </v-col>

        <v-col cols="12" md="6" xs="1" class="col-padding-value">
          <v-row justify="start" class="space-between-row">
            <v-col md="1" class="col-label-mobile" style="width: fit-content">
              <div class="pe-1" outlined tile>
                <span>{{ $t("message.label-small") }}</span>
              </div>
            </v-col>
            <v-col
              md="2"
              class="padding-mobile padding-desktop"
              style="padding:0 0 10px 10px; "
            >
              <div class="pe-2" outlined tile>
                <v-text-field
                  class="field-input"
                  type="number"
                  md="2"
                  block
                  outlined
                />
              </div>
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>

            <v-col md="1" class="col-label-mobile" style="width: fit-content">
              <div class="pe-2" outlined tile>
                <span>{{ $t("message.label-medium") }}:</span>
              </div>
            </v-col>
            <v-col
              md="2"
              class="padding-mobile padding-desktop"
              style="padding:0 0 10px 10px; "
            >
              <div class="pe-2" outlined tile>
                <v-text-field
                  class="field-input"
                  type="number"
                  md="2"
                  block
                  outlined
                />
              </div>
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>

            <v-col md="1" class="col-label-mobile" style="width: fit-content">
              <div class="pe-1" outlined tile>
                <span>{{ $t("message.label-big") }}:</span>
              </div>
            </v-col>
            <v-col
              md="2"
              class="padding-mobile padding-desktop"
              style="padding:0 0 10px 10px;"
            >
              <div class="pe-2" outlined tile>
                <v-text-field
                  class="field-input"
                  type="number"
                  md="2"
                  block
                  outlined
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row cols="12" align="center">
        <v-col cols="12" md="4" xs="1" class="col-padding-label">
          <span class="label-title">{{ $t("message.label-integration") }}</span>
        </v-col>
        <v-col md="6" class="col-padding-value">
          <v-text-field outlined class="field-input"></v-text-field>
        </v-col>
      </v-row>

      <v-row cols="12">
        <v-col cols="12" md="4" xs="1" class="col-padding-label">
          <span class="label-title">{{ $t("message.label-memo") }}</span>
        </v-col>
        <v-col md="6" class="col-padding-value">
          <v-textarea
            name="input-7-4"
            outlined
            class="field-input"
          ></v-textarea>
        </v-col>
      </v-row>

      <div class="fixed-div">
        <v-row cols="12" class="row-btn">
          <v-col cols="4" md="9" xs="1" class="btn-col">
            <v-btn
              class="btn-back-class"
              rounded
              color="secondary"
              dark
              @click.prevent="goBack"
            >
              {{ $t("message.btn-back") }}
            </v-btn>
          </v-col>
          <v-col cols="4" md="1" xs="1" class="btn-col">
            <v-btn class="btn-edit-class" rounded color="primary" dark>
              {{ $t("message.btn-edit") }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "LockerDetail",
  data: () => ({
    justify: ["start"],
  }),
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
@media only screen and (max-width: 764px) {
  .field-input {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
  .col-padding-label {
    padding-bottom: 3px;
  }
  .col-padding-value {
    padding-top: 0px;
  }
  .nested-label-col-1 {
    padding-bottom: 0;
  }
  .sublist-mobile-col {
    padding-top: 0px !important;
    width: 50% !important;
  }
  .sublist-mobile-title {
    padding-left: 1em !important;
  }
  .sublist-field-mobile .v-input .v-input__slot {
    margin: 0px;
    width: 15vw !important;
  }
  .field-input-sublist {
    width: 60px;
    margin-left: auto !important;
    margin-right: auto !important;
    display: block !important;
  }
  .col-label-mobile {
    /* margin-left: 30px; */
  }
  .padding-mobile {
    padding: 0 0 10px 10px;
    width: 5vw;
  }
  .space-between-row {
    justify-content: space-around !important;
  }
  .row-mobile {
    justify-content: space-between;
  }
  .row-btn {
    justify-content: space-around !important;
  }
  .btn-col {
    width: fit-content !important;
  }
}
.label-title {
  text-transform: capitalize;
  color: #3f51b5;
  font-weight: bold;
}
.sublist-field-col .v-input .v-input__slot {
  /* margin: 0px;
  width: 5vw; */
}
.padding-desktop {
  padding: 0 0 10px 10px;
  width: 75px;
  /* margin-right: 2em; */
}
.fixed-div {
  margin-top: 8%;
  bottom: 0px;
  right: 50%;
}
.field-input .v-text-field__details {
  display: none !important;
  padding-left: 0px;
}
.field-input-address .v-text-field__slot textarea {
  height: 20vh !important;
}
.field-input-address .v-input__slot {
  min-height: unset !important;
}
</style>
